import React, { FC } from 'react';

import { navigate } from 'gatsby-link';

import './styles.scss';

const App: FC = () => {
  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-top-inner">
          <span>&copy; {new Date().getFullYear()} InspectContent.com</span>
          <span className="mx-2">|</span>
          <span className="term-policy" onClick={() => navigate('/')}>
            Home
          </span>
          <span className="mx-2">|</span>
          <span className="term-policy" onClick={() => navigate('/terms-of-use')}>
            Terms of service
          </span>
          <span className="mx-2">|</span>
          <span className="term-policy" onClick={() => navigate('/privacy-policy')}>
            Privacy policy
          </span>
          <span className="mx-2">|</span>
          <span className="term-policy" onClick={() => navigate('/contact')}>
            Contact
          </span>
        </div>
      </div>
    </div>
  );
};

export default App;
