import React, { FC, ReactNode, useEffect, useState } from 'react';
import { homeLogoWithText } from '../../assets';

import { Helmet } from 'react-helmet';
import Footer from './footer';

// import topHeader from '../../assets/top-header.svg';
// import topHeaderMobile from '../../assets/top-header-mobile.svg';

import mobile from '../../assets/mobile.svg';
import tab from '../../assets/tab.png';
import full from '../../assets/full.png';

import './styles.scss';
import { Link } from 'gatsby';

interface LayoutProps {
  children: ReactNode;
  title: string;
  metaName: string;
  metaContent: string;
}

const LayoutProps: FC<LayoutProps> = ({ children, title, metaName, metaContent }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  useEffect(() => {
    const width = global && global.window && global.window.screen && global.window.screen.width;
    if (width < 1300 && width > 991) {
      setIsMobile(false);
      setIsTab(true);
    }
    if (width < 991) {
      setIsMobile(true);
      setIsTab(false);
    }
  }, []);

  return (
    <div className={` ${false ? 'theme-dark' : 'theme-light'}  page-container `}>
      {/* <img src={isMobile ? topHeaderMobile : topHeader} alt='header' style={{width: '100%', height: 'auto'}} className='cursor-pointer' onClick={() => window.open('https://promo.hexomatic.com', '_blank')}/> */}
      <img src={isMobile ? mobile : isTab ? tab : full} alt='header' style={{width: '100%', height: 'auto', cursor: 'pointer'}} className='cursor-pointer' onClick={() => window.open('https://hexomatic.com', '_blank')}/>
      <Helmet>
        <title>{title}</title>
        <meta name={metaName} content={metaContent} />
        <html lang="en" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaContent} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={global && global.window && global.window.location.href} />
        <meta property="og:image" content={require('../../assets/og-image.png')} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaContent} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={require('../../assets/og-image.png')} />
      </Helmet>
      {typeof window !== 'undefined' && window.location.pathname.indexOf('/') === -1 && (
        <div className="logo-wrapper">
          <Link to="/">
            <span>{homeLogoWithText}</span>
          </Link>
        </div>
      )}
      <div className="content-wrap">{children}</div>
      <Footer />
    </div>
  );
};

export default LayoutProps;
